import { useCallback, useEffect, useReducer, useRef, useState } from "react"
import { CELL_INFO, S_TYPE } from "../components/Grid";
import { BACKWARDS_DIFF_0, BACKWARDS_DIFF_1, BACKWARDS_DIFF_2, BACKWARDS_DIFF_3, CATEGORY_SHOW_DELAY, CATEGORY_SHOW_TIME, END_TURN_DELAY, GUESS_ALL_MULTICOLOR, REROLL_DELAY } from "../models/Game.constants";
import { PlayerMeta } from "../models/Player.model";
import { GameReducer, initialState, State } from "./reducer";
import { SOUNDS, useAudio } from "./useAudio";

export const POSITION = [
  72, 64, 56, 48, 40, 32, 24, 16, 8, 0,
  1, 9, 17, 25, 33, 41, 49, 57, 65, 73,
  74, 66, 58, 50, 42, 34, 26, 18, 10, 2,
  3, 11, 19, 27, 35, 43, 51, 59, 67, 75,
  76, 68, 60, 52, 44, 36, 28, 20, 12, 4,
  5, 13, 21, 29, 37, 45, 53, 61, 69, 77,
  78, 70, 62, 54, 46, 38, 30, 22, 14, 6,
  7, 15, 23, 31, 39, 47, 55, 63, 71, 79
]

/*
UseGame only updates the state of the local game and triggers socket eventsif needed
*/
export const useGame = (socket: any) => {

  // state
  const [state, dispatch] = useReducer(GameReducer, initialState);

  const startTurnAudio = useAudio(SOUNDS.START_TURN, {})
  const zamparAudio = useAudio(SOUNDS.ZAMPAR, {})

  let updatedState = useRef(state);

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Modals variables
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const [showDiceModal, setShowDiceModal] = useState<boolean>(false);
  const [showQuestionModal, setShowQuestionModal] = useState<boolean>(false);
  const [showMulticolorModal, setShowMulticolorModal] = useState<boolean>(false);
  const [showCommentModal, setShowCommentModal] = useState<boolean>(false);

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Methods to update useSates (to render)
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    updatedState.current = state;
  }, [state])

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Socket events
  //  only the user of the actions emits the event, and server will broadcast the consequence
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const movePlayer = (userId: number, n: number, multicolor_success: boolean) => {
    checkPlayersAndCallback(() => {
      socket.emit('movePlayer', { roomCode: updatedState.current.roomCode, userId, n, multicolor_success })
    })
  }

  const prepareQuestionEvent = () => {
    let currentPlayer = updatedState.current.players[updatedState.current.currentTurnPlayer];
    let category = CELL_INFO[POSITION[currentPlayer?.playerPosition]].type;

    let body = {
      roomCode: updatedState.current.roomCode,
      category,
      difficulty: getDifficulty(currentPlayer?.playerPosition),
    }
    if (body.roomCode !== '') {
      checkPlayersAndCallback(() => {
        socket.emit('prepareQuestion', body)
      })
    }
  }

  const setGameStateEvent = (gameState: string) => {
    checkPlayersAndCallback(() => {
      socket.emit('setGameState', { roomCode: updatedState.current.roomCode, gameState });
    })
  }

  const chooseMulticolor = (category1: any, category2: any) => {
    checkPlayersAndCallback(() => {
      let currentPlayer = updatedState.current.players[updatedState.current.currentTurnPlayer];

      socket.emit('prepareMulticolorQuestions', { roomCode: updatedState.current.roomCode, categories: [category1, category2], difficulty: getDifficulty(currentPlayer?.playerPosition) });
    })
  }

  const prepareBlackholeQuestions = () => {
    checkPlayersAndCallback(() => {
      socket.emit('prepareBlackholeQuestions', { roomCode: updatedState.current.roomCode });
    })
  }

  const nextTurn = () => {
    setTimeout(() => {
      setGameStateEvent('END_TURN');
    }, END_TURN_DELAY * 1000)
  }

  const updatePlayer = (modifiedPlayer: PlayerMeta) => {
    checkPlayersAndCallback(() => {
      socket.emit('updatePlayer', { modifiedPlayer });
    })
  }

  const startTurnEvent = () => {
    checkPlayersAndCallback(() => {
      socket.emit('broadcast', { event: 'startTurn', value: { show: true, startTimestamp: updatedState.current.startTimestampState, endTimestamp: updatedState.current.endTimestampState }, roomCode: updatedState.current.roomCode });
    })
  }

  const removeCurrentRemainingQuestion = () => {
    checkPlayersAndCallback(() => {
      socket.emit('removeCurrentRemainingQuestion', { roomCode: updatedState.current.roomCode })
    })
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Methods Game Logic
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const initReducer = useCallback((state: State, roomPlayers: PlayerMeta[]) => {
    dispatch({ type: "INIT_REDUCER", state, roomPlayers });
  }, [])

  const onUnmount = () => {
    dispatch({ type: "ON_UNMOUNT_COMPONENT" });
  }

  const startTurn = () => {
    if (updatedState?.current?.players) {
      let currentPlayer = updatedState.current.players[updatedState.current.currentTurnPlayer];
      if (currentPlayer) {
        if (currentPlayer.passTurn === 0) {
          startTurnEvent();
          if (updatedState.current.isMyTurn) startTurnAudio.play();
        }
        else {
          currentPlayer.passTurn--;
          updatePlayer(currentPlayer);
          nextTurn();
        }
      }
    }
  }

  const move = (
    N: number,
    multicolor_success: boolean
  ) => {
    if (updatedState.current.players && updatedState.current.players[updatedState.current.currentTurnPlayer]) {
      let currentPlayer = updatedState.current.players[updatedState.current.currentTurnPlayer];

      if (currentPlayer) {
        movePlayer(currentPlayer.user.id, N, multicolor_success);
      }
    }
  }

  const prepareQuestionFlow = () => {
    const delay = calculateDisplayQuestionDelay();
    // wait until the end of all animations.
    setTimeout(() => {
      if (updatedState.current.players) {
        let currentPlayer = updatedState.current.players[updatedState.current.currentTurnPlayer];
        let category = CELL_INFO[POSITION[currentPlayer?.playerPosition]];
        if (currentPlayer) {
          switch (category?.type) {
            case S_TYPE.YELLOW:
            case S_TYPE.ORANGE:
            case S_TYPE.GREEN:
            case S_TYPE.BLUE:
              prepareQuestionEvent();
              break;
            case S_TYPE.MULTICOLOR:
              if (updatedState.current.multicolorQueue.length !== 0) {
                setGameStateEvent("CATEGORY");
              } else {
                setGameStateEvent("MULTICOLOR");
              }
              break;
            case S_TYPE.BLACKHOLE:
              if (updatedState.current.blackholeQueue.length !== 0) {
                setGameStateEvent("CATEGORY");
              } else {
                setGameStateEvent("BLACKHOLE");
              }
              break;
            case S_TYPE.SECURITY_POINT:
              nextTurn();
              break;
          }
        }
      }
    }, delay * 1000);
  }

  const displayCategory = () => {
    setTimeout(() => {
      checkPlayersAndCallback(() => {
        let currentPlayer = updatedState.current.players[updatedState.current.currentTurnPlayer];
        if (currentPlayer?.playerPosition !== -1) {
          // after CATEGORY_SHOW_TIME close modal and open the question modal
          setGameStateEvent("QUESTION");
        }
      })
    }, CATEGORY_SHOW_TIME * 1000)

  }

  const handleCommentModal = (guessed: boolean) => {
    hideCommentModal();
    handleAnswer(guessed);
  }

  const handleAnswer = (guessed: boolean) => {
    if (updatedState?.current?.multicolorQueue.length === 0 && updatedState?.current?.blackholeQueue.length === 0) {
      if (guessed) {
        let currentPlayer = updatedState.current.players[updatedState.current.currentTurnPlayer];
        let category = CELL_INFO[POSITION[currentPlayer?.playerPosition]];
        if (category?.type === S_TYPE.MULTICOLOR) {
          move(GUESS_ALL_MULTICOLOR, true);
        }
        else {
          setTimeout(() => {
            setGameStateEvent("START_TURN");
          }, REROLL_DELAY * 1000)
        }
      } else {
        removeCurrentRemainingQuestion();
        goBackwards();
        nextTurn();
      }
    } else if (updatedState?.current?.multicolorQueue.length !== 0) {
      // if failed, players pass turn
      if (!guessed) {
        nextTurn();
      }
      // if guesses try the next one, and if guessed too, then advance 12 cells
      else {
        setGameStateEvent("CATEGORY");
      }
    } else { // blackhole.length !== 0
      // if failed, players loses 2 turns
      if (!guessed) {
        if (updatedState?.current?.players) {
          let p = updatedState.current.players[updatedState.current.currentTurnPlayer];
          p.passTurn = 2;
          updatePlayer(p);
          nextTurn();
        }
      }
      // if guessed all 4 then roll again
      else {
        setGameStateEvent("CATEGORY");
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  UI Methods
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const displayQuestion = (show: boolean) => {
    setShowQuestionModal(show);
  }

  const hideQuestionModalAndShowCommentModal = () => {
    setShowQuestionModal(false);
    setShowCommentModal(true);
  }

  const displayMulticolor = (show: boolean) => {
    setShowMulticolorModal(show);
  }

  const hideMulticolor = () => {
    setShowMulticolorModal(false);
  }

  const hideCommentModal = () => {
    setShowCommentModal(false);
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Auxiliar Methods
  //
  //////////////////////////////////////////////////////////////////////////////////////////////////////

  const needToSwap = (pos: number) => {
    if (pos !== -1 && pos < 80 && pos >= 0 && CELL_INFO[POSITION[pos]].type !== S_TYPE.SECURITY_POINT && CELL_INFO[POSITION[pos]].type !== S_TYPE.BLACKHOLE && updatedState?.current?.players) {
      for (let turn in updatedState.current.players) {
        if (parseInt(turn) !== updatedState.current.currentTurnPlayer && updatedState.current.players[turn]?.playerPosition === pos) return turn;
      }
    }
    return undefined;
  }

  const calculateDisplayQuestionDelay = () => {
    if (updatedState.current.multicolorQueue.length === 0 && updatedState.current.blackholeQueue.length === 0) return CATEGORY_SHOW_DELAY;
    return 0;
  }

  const getDifficulty = (cell: number) => {
    if (cell < 21) return 0;
    if (cell < 41) return 1;
    if (cell < 61) return 2;
    return 3;
  }

  const goBackwards = () => {
    let id = parseInt(localStorage.getItem('userId') || '') || -1;
    if (updatedState.current.players[updatedState.current.currentTurnPlayer]?.user.id === id) {
      let n;
      let currentPos = updatedState.current.players[updatedState.current.currentTurnPlayer]?.playerPosition
      if (currentPos) {
        if (currentPos < 20) n = BACKWARDS_DIFF_0;
        else if (currentPos < 40) n = BACKWARDS_DIFF_1;
        else if (currentPos < 60) n = BACKWARDS_DIFF_2;
        else n = BACKWARDS_DIFF_3;

        move(-n, false);
      }
    }
  }

  const checkPlayersAndCallback = (callback: Function) => {
    if (updatedState.current.roomCode !== '') {
      // in case the player dc'd in middle of their turn
      socket.emit('requestCurrentPlayers', { roomCode: updatedState.current.roomCode }, (res: { players: PlayerMeta[], currentTurnPlayer: number }) => {
        if (res.players) {
          // check if current player is actually OK or DC
          let currentPlayer = res.players.find(p => p.playerTurn === res.currentTurnPlayer);
          if (currentPlayer?.status === "OK") {
            let id = parseInt(localStorage.getItem('userId') || '') || -1;
            if (currentPlayer.user.id === id) callback();
          }
          else {
            // next player sends the socket event
            let nextTurn = (res.currentTurnPlayer + 1) % Object.keys(res.players).length;
            let nextPlayer = res.players[nextTurn];
            let id = parseInt(localStorage.getItem('userId') || '') || -1;
            if (nextPlayer.user.id === id) callback();
          }
        }
      })
    }
  }

  return [
    state, initReducer, checkPlayersAndCallback, setGameStateEvent,
    startTurn,
    showDiceModal, setShowDiceModal,
    move,
    prepareQuestionFlow, prepareBlackholeQuestions,
    displayCategory, displayQuestion, showQuestionModal,
    handleAnswer,
    showMulticolorModal, displayMulticolor, hideMulticolor, chooseMulticolor,
    onUnmount,
    handleCommentModal, showCommentModal,
    hideQuestionModalAndShowCommentModal,

  ] as [
      State, (state: State, roomPlayers: PlayerMeta[]) => void, (callback: Function) => void, (gameState: string) => void,
      () => void,
      boolean, (show: boolean) => void,
      (newPos: number, multicolor_success: boolean) => void,
      () => void, () => void,
      () => void, (show: boolean) => void, boolean,
      (guessed: boolean) => void,
      boolean, (show: boolean) => void, () => void, (category1: any, category2: any) => void,
      () => void,
      (guessed: boolean) => void, boolean,
      () => void,
    ];
}
