import { Box, Button, makeStyles, Theme, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Socket } from 'socket.io-client';
import Background from '../../shared/Background';
import { config } from '../../shared/config';
import { FullRoom, Game, Room, Topic, User } from '../../shared/models';
import ButtonsModal from './ButtonsModal';
import CodeModal from './CodeModal';
import EndingModal from './EndingModal';
import GameComponent from './Game/GameComponent';
import { audioService } from './Game/hooks/audioService.service';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';
import { usePrev } from './Game/hooks/usePrev';
import { PlayerMeta } from './Game/models/Player.model';
import Menu from './Menu';
import WaitingModal from './WaitingModal';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: 'hidden',
  },
  content: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 0
  },
  logo: {
    marginBottom: '3rem',
  },
  sides: {
    padding: '2rem',
    minWidth: '200px',
    maxWidth: '325px',
    textAlign: 'left'
  },
  center: {
    flex: 1,
    display: 'flex',
  },
  roomTitle: {
    position: 'fixed',
    width: '100vw',
    height: '26px',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
    margin: '11px',
  },
  button: {
    color: '#FFFFFF',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    width: '204px',
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '24px',
    marginBottom: '1rem',
  },
  green: {
    backgroundColor: '#83CD24',
    '&:hover': {
      backgroundColor: '#75B820',
    }
  },
  grey: {
    backgroundColor: '#C2C2C2'
  },
  under: {
    position: 'relative',
    zIndex: -1
  }
}));

interface GameProps {
  socket: Socket
}

interface GameParams {
  code: string;
}

export enum STATE {
  WAITING, PLAYING, PODIUM
}

const GameView: FC<GameProps> = ({socket}) => {
  const classes = useStyles();
  const { code }: GameParams = useParams();
  
  const [room, setRoom] = useState<Room>({id: -1, roomCode: '', maxPlayers: 0, topic: {} as Topic, roomPlayers: []});
  
  const [game, setGame] = useState<Game>({id: -1, createdAt: new Date(), currentTurnPlayer: -1, gameState: "NONE", remainingQuestions: [], multiColorQueue: [], blackholeQueue: [], currentRemainingQuestion: null, startTimestampState: null, endTimestampState: null})
  
  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  const [roomState, setRoomState] = useState<number>(-1);

  let history = useHistory();

  const prevGame = usePrev(game);
  
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //   Room Variables
  // 
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  const [startedGame, setStartedGame] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [showWaitingModal, setshowWaitingModal] = useState<boolean>(false);
  const [canActionAdmin, setcanActionAdmin] = useState<boolean>(true);
  const [showCancelModal, setshowCancelModal] = useState<boolean>(false);
  const [showExitModal, setshowExitModal] = useState<boolean>(false);
  const [kickPlayerId, setKickPlayerId] = useState<number>(-1);
  const [showKickModal, setshowKickModal] = useState<boolean>(false);
  const [showUpsModal, setshowUpsModal] = useState<boolean>(false);
  const [canceledGame, setCanceledGame] = useState<boolean>(false);
  const [showCodeModal, setshowCodeModal] = useState<boolean>(false);
  const [showPodiumModal, setshowPodiumModal] = useState<boolean>(false);
  const [errorCodeMsg, setErrorCodeMsg] = useState<string>('');
  const [winner, setWinner] = useState<User|null>();  
  const [currentGamestate, setCurrentGamestate] = useState<string>('');
  const [forceNextTurn, doForceNextTurn] = useState(0);  

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Initializations
  //
  
  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    audioService.init();
  }, [])

  // get and set local room
  useEffect(() => {
    getRoom();
    return () => {
      setRoom({id: -1, roomCode: '', maxPlayers: 0, topic: {} as Topic, roomPlayers: []});
      setGame({id: -1, createdAt: new Date(), currentTurnPlayer: -1, gameState: "NONE", remainingQuestions: [], multiColorQueue: [], blackholeQueue: [], currentRemainingQuestion: null, startTimestampState: null, endTimestampState: null});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const getRoom = () => {
    axios.get(`${config.apiUrl}/rooms/${code}`).then(res => {
      if (res.data !== 'Room not found') {
        let fullRoom: FullRoom = res.data;

        let userId = localStorage.getItem('userId');
        if (userId) {
          socket.emit('assignedRoom', {roomCode: code, userId: userId})
          setGame(fullRoom.game);
          delete res.data.game;
          setRoom(res.data)
        
          setIsAdmin(res.data.roomPlayers.find((p: PlayerMeta) => p.user.id === parseInt(userId ? userId : '') && p.isAdmin));
        }
        else {
          history.push('/');
        }
      } else {
        history.push('/');
      }
    })
  }

  // User joining room handler. If user is not in the roomPlayers list goes to '/'
  useEffect(() => {
    if (room.id !== -1) {
      let userId = localStorage.getItem('userId');
      if (!userId || (room.roomPlayers && !room.roomPlayers.find(p => p.user.id === parseInt(userId ? userId : '')))) {
        history.push('/');
        // localStorage.removeItem('userId');
      }
    }
  }, [room, history])

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Sockets events listeners
  //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    let isCancelled = false;
    const runAsync = async () => {
      try {
        socket.on('connect', () => {
          if (room.id !== -1) {
            socket.emit('assignedRoom', {roomCode: room.roomCode, userId: localStorage.getItem('userId')})
          }
        })

        socket.on('playerJoined', (res) => {
          if (res) { 
            delete res.data?.game;
            setRoom(res.data);
          }
        })

        socket.on('playerLeft', (res) => {
          if (res) {
            if (res.data.game) {
              setGame(res.data.game);
              delete res.data.game;
            }
            setRoom(res.data);
          }
        })

        socket.on('roomReset', (res) => {
          if (res) {
            if (res.data.game) {
              setGame(res.data.game);
              delete res.data.game;
            }
            setRoom(res.data);
          }
        })

        socket.on('cancelGame', () => {
          setCanceledGame(true);
        })

        socket.on('passWinner', (_winner) => {
          onPassWinner(_winner);
        })
      } catch (e) {
        if (!isCancelled) throw e;
      }
    }
    if (socket) runAsync();
    return () => {
      socket.off('playerJoined', () => {})
      socket.off('playerLeft', () => {})
      socket.off('gameStateUpdated', () => {})
      socket.off('cancelGame', () => {})
      isCancelled = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket])
  
  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  RoomState and GameState listeners and handler
  //
  ////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    switch(roomState) {
      case STATE.WAITING:
        setshowUpsModal(false);
        setshowWaitingModal(true);
        setcanActionAdmin(true)
        break;
      case STATE.PLAYING:
        setshowWaitingModal(false);
        setcanActionAdmin(false);
        break;
      case STATE.PODIUM:
        if (!winner) setWinner(room.roomPlayers.find(p => p.playerPosition === 80)?.user);
        if (canceledGame) {
          setshowUpsModal(true)
          setshowPodiumModal(false);
        }
        else {
          setshowPodiumModal(true);
          setshowUpsModal(false);
        }
        setcanActionAdmin(false);
        setshowWaitingModal(false);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomState])
  
  useEffect(() => {
    if (game?.gameState !== prevGame?.gameState) {
      setGame(game);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game.gameState])
  
  useEffect(() => {
    if (currentGamestate !== '') {
      switch (currentGamestate) {
        case "IDLE":
        case "MOVE_PLAYER":  
        case "MULTICOLOR_SUCCESS":
        case "END_TURN":
        setcanActionAdmin(true);
        break;
        default: 
        setcanActionAdmin(false);
      }

        // update state
      if (currentGamestate === "IDLE") setRoomState(STATE.WAITING)
      else if (currentGamestate !== "IDLE" && currentGamestate !== "END_GAME") setRoomState(STATE.PLAYING)
      else if (currentGamestate === "END_GAME") setRoomState(STATE.PODIUM)
    }
  }, [currentGamestate])

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Button clicks
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  const onStartGameClick = () => {
    if (game && game.id !== -1) {
      buttonClickAudio.play()
      setStartedGame(true);
      socket.emit('onStartGameClick', {roomCode: room.roomCode})
    }
  }

  const onCancelGame = () => {
    setshowCancelModal(true);
  }

  const kickPlayer = (id: number) => {
    setKickPlayerId(id);
    setshowKickModal(true);
  }

  const onCancelKick = () => {
    setKickPlayerId(-1);
    setshowKickModal(false);
  }

  const onExitClick = () => {
    if (room.id !== -1) {
      socket.emit('leaveRoom', {roomId: room.id, userId: localStorage.userId}, (res: any) => {
        if (res.status.code === 200) {
          history.push('/');
        }
      });
    }
  }

  const onKickClick = () => {
    if (kickPlayerId !== -1 && room.id !== -1) {
      socket.emit('kickPlayer', {userId: kickPlayerId, roomId: room.id}, (res: any) => {
        if (res) {
          // if(res.data.room) setRoom(res.data.room);
          setshowKickModal(false);
          doForceNextTurn(prev => prev + 1);
        }
      })
    }
  }

  const onCancelGameAccept = () => {
    if (isAdmin) {
      setshowCodeModal(true)
    } else {
      setshowUpsModal(false);
      setshowWaitingModal(true);
    }
  }

  const onStayRoom = () => {
    setshowPodiumModal(false);
    setshowWaitingModal(true);
  }

  const onNewGame = (code: string) => {
    if (!code) {
      setErrorCodeMsg('Ingrese el código aquí')
    } else {
      socket.emit('newGame', {adminCode: code, roomId: room.id}, (res: any) => {
        if (res.status.code === 200) {
          if (res.data.room) {
            setErrorCodeMsg('');
            setRoom(res.data.room);
            setshowCodeModal(false);
            setshowUpsModal(false);
          }
        } else if (res.status.code === 404) {
          setErrorCodeMsg('Código no existe o se le ha agotado los usos');
        }
      })
    }
  }

  const backToUps = () => {
    setshowCodeModal(false);
    setshowUpsModal(true);
  }

  const onConfirmCancelGame = () => {
    setshowCancelModal(false);
    socket.emit('cancelGame', {roomCode: room.roomCode});
  }

  const onPassWinner = (_winner: User|null) => {
    if (_winner) {
      setWinner(_winner);
      setCanceledGame(false);
    }
    else {
      setCurrentGamestate("END_GAME");
      setshowUpsModal(true);
      setCanceledGame(true);
    }
  }

  const onPassGameState = (gameState: string) => {
    setCurrentGamestate(gameState);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  //  Auxiliars methods
  //
  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  const isWinner = () => {
    return winner && winner.id === parseInt(localStorage.userId);
  }

  return (
    <div className={classes.root}>
      <Background color='blue'/>
      { !isAdmin && showWaitingModal && currentGamestate === "IDLE" &&  
        <WaitingModal />
      }
      <Box className={classes.content}>
        <Typography
          className={classes.roomTitle}
        >
          CÓDIGO DE LA SALA: {room.roomCode}
        </Typography>
        <Box className={classes.sides + (!canActionAdmin ? ' '+classes.under:  '')}>
          <Box className={classes.logo}>
            <img src="/static/images/logo.png" alt="Logo" width="165px"/>
          </Box>
          {
            isAdmin &&
            <Box>
              {
                currentGamestate === "IDLE" && 
                <Button
                  className={classes.button + ' ' + classes.green}
                  variant="contained"
                  onClick={onStartGameClick}
                  disabled={!startedGame && room.roomPlayers && room.roomPlayers.length < 2}
                >
                  COMENZAR PARTIDA
                </Button>
              }
              {
                currentGamestate !== "IDLE" &&
                <Button
                  className={classes.button + ' ' + classes.grey}
                  variant="contained"
                  onClick={onCancelGame}
                >
                  FINALIZAR PARTIDA
                </Button>
              }
            </Box>
          }
        </Box>
        <GameComponent room={room} game={game} socket={socket} passGameState={onPassGameState} onPassWinner={onPassWinner} forceNextTurn={forceNextTurn}/>
      </Box>
      <Menu onExit={() => {setshowExitModal(true); buttonClickAudio.play();}} isAdmin={isAdmin} players={room.roomPlayers || []} maxPlayers={room.maxPlayers || 0} onKick={(i) => kickPlayer(i)}/>
      {
        showExitModal &&
        <ButtonsModal 
        label={'¿ESTÁ SEGURO/A QUE DESEA ABANDONAR LA PARTIDA?'} 
        onClose={() => {setshowExitModal(false); buttonClickAudio.play();}}
        onAccept={onExitClick}
        />
      }
      {
        showKickModal &&
        <ButtonsModal 
        label={'¿ESTÁ SEGURO/A QUE DESEA ELIMINAR ESTE JUGADOR?'} 
        onClose={onCancelKick}
        onAccept={onKickClick}
        />
      }
      {
        currentGamestate === "END_GAME" && showUpsModal && canceledGame &&
        <EndingModal title="¡UPS!" description={`${isAdmin ? 'HAS': 'EL ADMINISTRADOR HA'} CANCELADO LA PARTIDA`}
          onAcceptLabel={`${isAdmin ? 'COMENZAR UNA NUEVA PARTIDA': 'QUEDARSE EN LA SALA'} `} onCancelLabel="SALIR"
          onAccept={onCancelGameAccept} onCancel={onExitClick}/>
      }
      {
        currentGamestate === "END_GAME" && showPodiumModal && !canceledGame &&
        <EndingModal title={isWinner() ? '¡FELICIDADES!': '¡UPS!'} description={isWinner() ? '¡HAS GANADO LA PARTIDA!': `No hubo suerte esta vez, ha ganado ${winner?.nickname}.`} 
          onAcceptLabel={`${isAdmin ? 'COMENZAR UNA NUEVA PARTIDA': 'QUEDARSE EN LA SALA'} `} onCancelLabel="SALIR"
          onAccept={isAdmin ? onCancelGameAccept: onStayRoom} onCancel={onExitClick}/>
      }
      {
        currentGamestate === "END_GAME" && showCodeModal &&  
        <CodeModal onAccept={(code: string) => onNewGame(code)} onCancel={backToUps} errorCodeMsg={errorCodeMsg}/>
      }
      {
        showCancelModal &&
        <ButtonsModal 
        label={'¿ESTÁ SEGURO/A QUE DESEA CANCELAR LA PARTIDA PARA TODOS LOS JUGADORES?'} 
    
        onClose={() => {setshowCancelModal(false); buttonClickAudio.play(); }}
        onAccept={onConfirmCancelGame}
        />
      }
    </div>
  )
}

export default GameView;
