import { Box, Button, Card, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles';


import CloseIcon from '@material-ui/icons/Close';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: 0,
    left: 0,
    zIndex: 1001,
    display: 'flex',
    justifyContent: 'center'
  },
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(41, 95, 155, 0.65)',
  },
  card: {
    position: 'absolute',
    top: '7rem',
    width: '100%',
    maxWidth: '588px',
    maxHeight: '196px',
    background: '#FFFFFF',
    boxShadow: '0px 11px 15px rgba(0, 0, 0, 0.2), 0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12)',
    borderRadius: '4px',
    padding: '16px 25px',
    textAlign: 'left'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '19px',
    lineHeight: '26px',
    letterSpacing: '0.25px',    
    color: 'rgba(87, 87, 87, 0.87)',
    marginBottom: '22px',
  },
  buttons: {
    marginTop: '2.6rem',
    marginBottom: '8px'
  },
  button: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.45px',
    textTransform: 'uppercase',
    width: '115px',
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '24px',
    marginRight: '1rem',
  },
  close: {
    cursor: 'pointer',
  },
  white: {
    color: '#FFFFFF !important'
  }
}))

interface ButtonsModalProps {
  onClose: () =>  void;
  onAccept: () =>  void;
  label: string;
}

const ButtonsModal: FC<ButtonsModalProps> = ({onClose, onAccept, label}) => {
  const classes = useStyles();

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {});

  const theme = createTheme({
    palette: {
      primary: {
        main: '#78AEEA',
      },
      secondary: {
        main: '#C2C2C2'
      }
    }
  })

  return (
    <Box className={classes.root}>
      <Box className={classes.background}></Box>
      <Card className={classes.card}>
        <Box className={classes.row}>
          <Typography className={classes.title}>
            {label}
          </Typography>
          <CloseIcon className={classes.close} color="inherit" onClick={() => {onClose(); buttonClickAudio.play();}}/>
        </Box>
        <Box className={classes.buttons} >
          <ThemeProvider theme={theme}>
            <Button className={classes.button + ' ' + classes.white} onClick={() => {onAccept(); buttonClickAudio.play()}}  variant="contained" color="primary">ACEPTAR</Button>
            <Button className={classes.button} onClick={() => {onClose(); buttonClickAudio.play()}} variant="outlined" color="primary">CANCELAR</Button>
          </ThemeProvider>
        </Box>
      </Card>
    </Box>
  )
}

export default ButtonsModal
