import { Box, Button, Card, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { SOUNDS, useAudio } from './Game/hooks/useAudio';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(41, 95, 155, 0.65)'
  },
  card: {
    width: '51%',
    height: '67%',
    backgroundImage: `url('/static/images/background_orange.png')`,
    backgroundRepeat: 'round',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& img': {
      width: '165px',
      marginTop: '3.6rem'
    }
  },
  buttons: {
    minWidth: '319px',
    marginTop: '2.6rem',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  button: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '15px',
    lineHeight: '26px',
    letterSpacing: '0.45px',
    textTransform: 'uppercase',
    width: '100%',
    boxShadow: '0px 2px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
    borderRadius: '24px',
    marginRight: '1rem',
    color: 'white',
    marginTop: '1rem',
  },
  green: {
    backgroundColor: '#83CD24',
    '&:hover': {
      backgroundColor: '#75B820',
    }
  },
  grey: {
    backgroundColor: '#C2C2C2',
  },
  text: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '58px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
  }
}))

interface EndingModalProps {
  title: string;
  description: string;
  onAcceptLabel?: string;
  onCancelLabel?: string;
  onAccept?: () => void;
  onCancel?: () => void;
}

const EndingModal: FC<EndingModalProps> = ({title, description, onAcceptLabel, onCancelLabel, onAccept, onCancel}) => {
  const classes = useStyles();

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Typography className={classes.text} variant="h2">{title}</Typography>
        <Typography className={classes.text} variant="h5">{description}</Typography>
        <Box className={classes.buttons} >
          {
            onAcceptLabel && onAccept && 
            <Button className={classes.button + ' ' + classes.green} onClick={() => {onAccept(); buttonClickAudio.play()}}  variant="contained">{onAcceptLabel}</Button>
          }
          {
            onCancelLabel && onCancel && 
            <Button className={classes.button + ' ' + classes.grey} onClick={() => {onCancel(); buttonClickAudio.play()}} variant="contained">{onCancelLabel}</Button>
          }
        </Box>
        <img src="/static/images/logo.png" alt="" />
      </Card>
    </Box>
  )
}

export default EndingModal
