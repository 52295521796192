import { Subject } from "rxjs";

const soundSubject = new Subject();
const musicSubject = new Subject();
const changedByCodeSubject = new Subject();

export var currentSoundVolume = 0.5;
export var currentMusicVolume = 0;
export var changedByCode = false;

export const audioService = {
  init: () => {
    let localStorageSoundSetting = localStorage.getItem('soundSetting');
    if (localStorageSoundSetting) {
      currentSoundVolume = parseFloat(localStorageSoundSetting);
      soundSubject.next({newVolume: currentSoundVolume, fromInit: true});
    }
    else localStorage.setItem('soundSetting', currentSoundVolume.toString());

    // music init done at useAudio line 52
  },
  changedSoundVolume: (newValue: number) => {
    currentSoundVolume = newValue;
    soundSubject.next({newVolume: newValue, fromInit: false});
    localStorage.setItem('soundSetting', currentSoundVolume.toString());
  },
  getNewSoundVolume: () => soundSubject.asObservable(),
  changedMusicVolume: (newValue: number) => {
    musicSubject.next(newValue);
    currentMusicVolume = newValue;
    localStorage.setItem('musicSetting', currentMusicVolume.toString());
  },
  getNewMusicVolume: () => musicSubject.asObservable(),
  changedMusicByCode: (newValue: number) => {
    changedByCode = true;
    currentMusicVolume = newValue;
    localStorage.setItem('musicSetting', currentMusicVolume.toString());
    changedByCodeSubject.next(currentMusicVolume);
    musicSubject.next(newValue);
  },
  getChangedByCode: () => changedByCodeSubject.asObservable()
};