import { useEffect, useRef } from "react";

import dies from '../../../../shared/sounds/Dados.mp3';
import buttonClick from '../../../../shared/sounds/MouseClick01.mp3';
import startTurn from '../../../../shared/sounds/StartButton1.mp3';
import correct from '../../../../shared/sounds/ScoringaPoint.mp3';
import wrong from '../../../../shared/sounds/WrongAnswer.mp3';
import move from '../../../../shared/sounds/AdvanceBoardPiece.mp3';
import zampar from '../../../../shared/sounds/Zampar.mp3';
import endGame from '../../../../shared/sounds/Victoria.mp3';

import backgroundMusic from '../../../../shared/sounds/backgroundMusic.mp3';
import { audioService, changedByCode, currentMusicVolume, currentSoundVolume } from "./audioService.service";

export enum SOUNDS {
  DIES, BUTTON_CLICK, START_TURN, CORRECT, WRONG, MOVE, ZAMPAR, END_GAME, BACKGROUND
}

export const useAudio = (src: SOUNDS, {loop = false}) => {

  const getSrc = (src: SOUNDS) => {
    switch(src) {
      case SOUNDS.DIES: return dies;
      case SOUNDS.BUTTON_CLICK: return buttonClick;
      case SOUNDS.START_TURN: return startTurn;
      case SOUNDS.CORRECT: return correct;
      case SOUNDS.WRONG: return wrong;
      case SOUNDS.MOVE: return move;
      case SOUNDS.ZAMPAR: return zampar;
      case SOUNDS.BACKGROUND: return backgroundMusic;
      default: return endGame;
    }
  }

  const audio = useRef(new Audio(getSrc(src)))

  useEffect(() => {
    setSoundVolume(currentSoundVolume, true);
    setMusicVolume(currentMusicVolume);

    let soundSubscription = audioService.getNewSoundVolume().subscribe((data: any) => {
      setSoundVolume(data.newVolume, data.fromInit);
    })
    let musicSubscription = audioService.getNewMusicVolume().subscribe((newVolume: any) => {
      setMusicVolume(newVolume);
    })

    audio.current.onplaying = function() {
      
      if (!changedByCode && currentMusicVolume === 0) {
        let localStorageMusicSetting = localStorage.getItem('musicSetting');
        audioService.changedMusicByCode(localStorageMusicSetting ? parseFloat(localStorageMusicSetting) : 0.01);
      }
    }
    return () => {
      soundSubscription.unsubscribe();
      musicSubscription.unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setSoundVolume = (newVolume: number, init: boolean) => {
    if (src === SOUNDS.BUTTON_CLICK || src === SOUNDS.CORRECT || src === SOUNDS.START_TURN) {
      audio.current.volume = 0.5 * newVolume;
      if (!init && src === SOUNDS.BUTTON_CLICK) audio.current.play();
    } else if (src === SOUNDS.DIES || src === SOUNDS.WRONG) {
      audio.current.volume = 0.8 * newVolume;
    } else if (src === SOUNDS.MOVE || src === SOUNDS.ZAMPAR) {
      audio.current.volume = newVolume;
    }
  }

  const setMusicVolume = (newVolume: number) => {
    if (src === SOUNDS.BACKGROUND) {
      audio.current.volume = newVolume;
      if (audio.current.currentTime === 0) audio.current.play();
    }
  }

  useEffect(() => {
    audio.current.loop = loop;
  }, [loop])

  return audio.current;
}