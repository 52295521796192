import { Box, Button, Card, CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { Question } from '../models/QuestionModal.model';
import { S_TYPE } from './Grid';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(41, 95, 155, 0.65);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3000,
  },
  top: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
    '& img': {
      margin: '0 22px',
      transform: 'scale(1.3)'
    },
    '& p': {
      width: 'fit-content'
    }
  },
  card: {
    width: '48%',
    overflow: 'visible',
    background: '#FFFFFF',
    boxShadow: '-4px 4px 5px 3px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    position: 'relative',
  },
  title: {
    flex: 1,
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
  },
  content: {
    padding: '90px',
    position: 'relative',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    width: '100%',
    maxWidth: '441px',
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.15px',
    textTransform: 'uppercase',
    border: '1px solid #575757',
    boxSizing: 'border-box',
    borderRadius: '24px',
    marginBottom: '1rem',
    color: '#575757',
  },
  timer: {
    color: '#78AEEA',
    margin: '20px',
    position: 'absolute',
    right: 0,
    bottom: 0
  },
  question: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '24px',
    lineHeight: '28px',
    letterSpacing: '0.15px',
    color: '#575757',
    marginBottom: '25px',
  },
  orange: {
    background: '#FFA258',
  },
  blue: {
    background: '#8BBFF9',
  },
  yellow: {
    background: '#FAD920',
  },
  green: {
    background: '#83CD24',
  },
  wrong: {
    background: '#F34F4F',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    '&:hover': {
      background: '#DA4747'
    }
  },
  correct: {
    background: '#83CD24',
    '-webkit-appearance': 'none',
    '-moz-appearance': 'none',
    appearance: 'none',
    '&:hover': {
      background: '#75B820'
    }
  }
}))

interface QuestionModalProps {
  color: string; // color of the player playing
  isMyTurn: boolean; // if it's my turn
  nickname: string;
  timeout: () => void; // pass up if timer ran out
  question: Question;
  topic: any;
  chooseAnswer: (value: number, correct: boolean) => void; // pass up the answer chosen
  finalAnswer: number | undefined; // passing down from socket the final random value,
  startTimestamp: number;
  endTimestamp: number;
}

/**
 * Clicking the button generates a random number from 1 to 6, and pass it up to send it to the server 
 * which returns a socket event that updates finalRoll and at updating finalRoll the dice will roll with this number 
 * for all sockets
 */
const QuestionModal: FC<QuestionModalProps> = ({ color, isMyTurn, nickname, timeout, question, topic, chooseAnswer, finalAnswer, startTimestamp, endTimestamp }) => {
  const classes = useStyles();
  const [progress, setProgress] = useState<number>(100);
  const [playerAnswered, setPlayerAnswered] = useState<boolean>(false);

  useEffect(() => {
    let timer: any = null
    if (progress > 0 && !timer && !playerAnswered) {
      timer = setInterval(() => {
        let timeLeft = endTimestamp - Date.now();
        let percent = timeLeft * 100 / (endTimestamp - startTimestamp);
        setProgress(prevProgress => prevProgress > 0 ? percent : 0)
      }, 1000)
    } else {
      clearInterval(timer)
      if (!playerAnswered) timeout()
    }
    return () => {
      clearInterval(timer);
    }
  }, [progress, playerAnswered, startTimestamp, endTimestamp, timeout])

  useEffect(() => {
    if (finalAnswer !== -1) setPlayerAnswered(true);
  }, [finalAnswer])

  const showResult = (answer: number) => {
    if (answer === question.correctAnswer) return classes.correct;
    if (answer === finalAnswer) return classes.wrong;
  }

  let backgroundColor;
  let subtopicTitle;
  switch (question.subtopic) {
    case S_TYPE.ORANGE: backgroundColor = classes.orange; subtopicTitle = topic.subtopic3; break;
    case S_TYPE.BLUE: backgroundColor = classes.blue; subtopicTitle = topic.subtopic2; break;
    case S_TYPE.YELLOW: backgroundColor = classes.yellow; subtopicTitle = topic.subtopic1; break;
    case S_TYPE.GREEN: backgroundColor = classes.green; subtopicTitle = topic.subtopic4; break;
  }

  return (
    <Box className={classes.root}>
      <Card className={classes.card}>
        <Box className={classes.top + ' ' + backgroundColor}>
          <Typography className={classes.title} style={{ textAlign: 'right' }}>{nickname}</Typography>
          <img src={`/static/images/player_${color}.png`} alt="" />
          <Typography className={classes.title} style={{ textAlign: 'left' }}>{subtopicTitle}</Typography>
        </Box>
        <Box className={classes.content}>
          <Typography className={classes.question}>{question.question}</Typography>
          <Box >
            <Button className={`${classes.button} ${playerAnswered ? showResult(0) : ''}`} onClick={() => chooseAnswer(0, 0 === question.correctAnswer)} disabled={!isMyTurn || playerAnswered}>{question.answer1}</Button>
            <Button className={`${classes.button} ${playerAnswered ? showResult(1) : ''}`} onClick={() => chooseAnswer(1, 1 === question.correctAnswer)} disabled={!isMyTurn || playerAnswered}>{question.answer2}</Button>
            {question.answer3 &&
              <Button className={`${classes.button} ${playerAnswered ? showResult(2) : ''}`} onClick={() => chooseAnswer(2, 2 === question.correctAnswer)} disabled={!isMyTurn || playerAnswered}>{question.answer3}</Button>
            }
            {question.answer4 &&
              <Button className={`${classes.button} ${playerAnswered ? showResult(3) : ''}`} onClick={() => chooseAnswer(3, 3 === question.correctAnswer)} disabled={!isMyTurn || playerAnswered}>{question.answer4}</Button>
            }
          </Box>
          <CircularProgress className={classes.timer} variant="determinate" value={progress} />
        </Box>
      </Card>
    </Box>
  )
}

export default QuestionModal
