import { Box, Card, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'

import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    width: '333px',
    maxHeight: '503px',
    right: '0px',
    top: '95px',
    padding: '18px'
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
  },
  row: {
    display: 'flex',
  }, 
  close: {
    cursor: 'pointer',
  },
  rules: {
    maxHeight: '410px',
    overflow: 'auto',
  },
  text: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textAlign: 'left',
    color: '#000000'
  },
  inline: {
    position: 'initial',
    width: '100%',
    maxHeight: '100%',
    padding: 0,
    boxShadow: 'none'
  },
}))

interface RulesProps {
  onClose: () => void;
  inline: boolean;
}

const Rules: FC<RulesProps> = ({onClose, inline}) => {
  const classes = useStyles();

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  return (
    <Card className={classes.root + (inline ? ' '+classes.inline: '')}>
      <Box className={classes.top}>
        <Box className={classes.row}>
          <InfoIcon color="action" style={{marginRight: '10px'}}/>
          <Typography className={classes.title}>
            REGLAMENTO
          </Typography>
        </Box>
        <CloseIcon className={classes.close} color="inherit" onClick={() => {onClose(); buttonClickAudio.play()}}/>
      </Box>
      <Box className={classes.rules}>
        <p className={classes.text}>
        Cada jugador <b>parte de la casilla de salida</b> de la primera columna en dirección a la casilla número 10 (final de la misma). Al llegar a la casilla número 10 accederá a la casilla número 11 y retornará en esa columna hasta la número 20. Desde la 20 pasará a la columna que empieza en la casilla número 21 en dirección casilla número 30, repitiendo el recorrido de ida y vuelta en cada columna hasta avanzar el final en la casilla de llegada. Con la puntuación obtenida del dado se avanza el número de casillas correspondiente. Dependiendo del color de la casilla alcanzada al jugador le corresponde responder a una tarjeta de preguntas con el mismo color (que corresponde a una determinada área de conocimiento). De cada tarjeta existen al menos dos niveles de preguntas (nivel básico-intermedio y nivel avanzado-experto) a las que se contesta según la posición en el tablero. El jugador debe responder a la pregunta de tres posibles respuestas. <b>Si acierta</b> volverá a tirar el dado y puede seguir avanzando. <b>Si no responde adecuadamente</b> retrocederá el número de casillas siguiente: <br/>
        &nbsp;&nbsp;&nbsp;&nbsp;Si se encuentra en las columas del <b>nivel básico</b> no retrocede casilla alguna. <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Si se encuentra en las columas del <b>nivel intermedio</b> retrocederá  una casilla. <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Si se encuentra en las columas del <b>nivel avanzado</b> retrocederá dos casillas. <br />
        &nbsp;&nbsp;&nbsp;&nbsp;Si se encuentra en las columas del <b>nivel experto</b> retrocederá tres casillas. <br />
        Cuando un jugador A llega a la casilla ocupada por otro jugador B, A <b>zampa</b> a B intercambiando sus posiciones (esto se puede producir tanto cuando se avanza como cuando se retrocede) y cogerá la tarjeta de respuestas correspondiente y avanza al acertar. <br />
        Si el jugador alcanza una casilla identificada <b>como punto seguro</b>, tiene que ceder el turno al siguiente jugador. Esta casilla nos protege de ser zampados, compartiendo casilla. <br />
        Si el jugador alcanza <b>una casilla multicolor</b> debe elegir dos colores de las tarjetas de respuesta, si acierta ambas <b>avanza 12 casillas</b>, si falla alguna permanece en la casilla sin retroceder. <br />
        Si el jugador cae en la casilla <b>agujero negro</b>, deberá responder una pregunta de cada color. Si responde correctamente a todas las preguntas vuelve a tirar el dado. Si falla alguna de las preguntas queda dos turnos sin jugar. Si coinciden dos o más jugadores en esta casilla no se zampas y comparten casilla prosiguiendo el juego con las reglas establecidas para esta casilla. <br />
        El jugador que primero alcanza la casilla llegada es el ganador de la partida. <br />
        No existe rebote en la casilla de llegada. 
        </p>
      </Box>
    </Card>
  )
}

export default Rules
