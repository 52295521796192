import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { useBoard } from "../hooks/useBoard";

export enum S_TYPE {
  YELLOW, BLUE, ORANGE, GREEN, SECURITY_POINT, MULTICOLOR, BLACKHOLE, SIDE, START, LOGO, END
}

// GAME CELLS
export const CELL_INFO = [
  {type: S_TYPE.GREEN, label: '10'}, {type: S_TYPE.BLUE, label: '11'}, {type: S_TYPE.GREEN, label: '30'}, {type: S_TYPE.YELLOW, label: '31'}, {type: S_TYPE.BLUE, label: '50'}, {type: S_TYPE.GREEN, label: '51'}, {type: S_TYPE.GREEN, label: '70'}, {type: S_TYPE.BLUE, label: '71'}, 
  {type: S_TYPE.YELLOW, label: '9'}, {type: S_TYPE.YELLOW, label: '12'}, {type: S_TYPE.BLUE, label: '29'}, {type: S_TYPE.GREEN, label: '32'}, {type: S_TYPE.YELLOW, label: '49'}, {type: S_TYPE.BLUE, label: '52'}, {type: S_TYPE.YELLOW, label: '69'}, {type: S_TYPE.ORANGE, label: '72'}, 
  {type: S_TYPE.ORANGE, label: '8'}, {type: S_TYPE.GREEN, label: '13'}, {type: S_TYPE.YELLOW, label: '28'}, {type: S_TYPE.BLUE, label: '33'}, {type: S_TYPE.GREEN, label: '48'}, {type: S_TYPE.YELLOW, label: '53'}, {type: S_TYPE.BLUE, label: '68'}, {type: S_TYPE.YELLOW, label: '73'}, 
  {type: S_TYPE.BLUE, label: '7'}, {type: S_TYPE.ORANGE, label: '14'}, {type: S_TYPE.MULTICOLOR, label: '27'}, {type: S_TYPE.ORANGE, label: '34'}, {type: S_TYPE.BLUE, label: '47'}, {type: S_TYPE.ORANGE, label: '54'}, {type: S_TYPE.GREEN, label: '67'}, {type: S_TYPE.GREEN, label: '74'}, 
  {type: S_TYPE.SECURITY_POINT, label: '6'}, {type: S_TYPE.YELLOW, label: '15'}, {type: S_TYPE.GREEN, label: '26'}, {type: S_TYPE.YELLOW, label: '35'}, {type: S_TYPE.SECURITY_POINT, label: '46'}, {type: S_TYPE.ORANGE, label: '55'}, {type: S_TYPE.YELLOW, label: '66'}, {type: S_TYPE.BLACKHOLE, label: ''}, 
  {type: S_TYPE.YELLOW, label: '5'}, {type: S_TYPE.ORANGE, label: '16'}, {type: S_TYPE.BLUE, label: '25'}, {type: S_TYPE.ORANGE, label: '36'}, {type: S_TYPE.ORANGE, label: '45'}, {type: S_TYPE.YELLOW, label: '56'}, {type: S_TYPE.ORANGE, label: '65'}, {type: S_TYPE.GREEN, label: '76'}, 
  {type: S_TYPE.GREEN, label: '4'}, {type: S_TYPE.YELLOW, label: '17'}, {type: S_TYPE.SECURITY_POINT, label: '24'}, {type: S_TYPE.GREEN, label: '37'}, {type: S_TYPE.YELLOW, label: '44'}, {type: S_TYPE.MULTICOLOR, label: '57'}, {type: S_TYPE.SECURITY_POINT, label: '64'}, {type: S_TYPE.ORANGE, label: '77'}, 
  {type: S_TYPE.ORANGE, label: '3'}, {type: S_TYPE.GREEN, label: '18'}, {type: S_TYPE.YELLOW, label: '23'}, {type: S_TYPE.ORANGE, label: '38'}, {type: S_TYPE.BLUE, label: '43'}, {type: S_TYPE.ORANGE, label: '58'}, {type: S_TYPE.GREEN, label: '63'}, {type: S_TYPE.ORANGE, label: '78'},
  {type: S_TYPE.BLUE, label: '2'}, {type: S_TYPE.GREEN, label: '19'}, {type: S_TYPE.BLUE, label: '22'}, {type: S_TYPE.BLUE, label: '39'}, {type: S_TYPE.GREEN, label: '42'}, {type: S_TYPE.ORANGE, label: '59'}, {type: S_TYPE.BLUE, label: '62'}, {type: S_TYPE.BLUE, label: '79'},
  {type: S_TYPE.YELLOW, label: '1'}, {type: S_TYPE.BLUE, label: '20'}, {type: S_TYPE.GREEN, label: '21'}, {type: S_TYPE.YELLOW, label: '40'}, {type: S_TYPE.ORANGE, label: '41'}, {type: S_TYPE.YELLOW, label: '60'}, {type: S_TYPE.ORANGE, label: '61'}, {type: S_TYPE.YELLOW, label: '80'},
]

const useStyles = makeStyles((theme: Theme) => ({
  grid: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5% 12.5%',
    gridTemplateRows: '7% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 8.4% 9%',
    background: 'white',
    backgroundColor: '#FFFFFF',
    border: '2px solid #FFFFFF',
    boxSizing: 'border-box',
    padding: '5px',

    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: '#575757',
  },
  yellowCell: {
    backgroundColor: '#FFE348'
  },
  blueCell: {
    backgroundColor: '#78AEEA'
  },
  orangeCell: {
    backgroundColor: '#FFA258'
  },
  greenCell: {
    backgroundColor: '#83CD24'
  },
  greyCell: {
    backgroundColor: '#E2E2E2'
  },
  blackCell: {
    backgroundColor: '#000000' 
  },
  white: {
    color: 'white'
  },
  square: {
    '& > img': {
      height: 'calc(100% - 22px)',
    },
    float: 'left',
    width: '100%',
    height: '100%',
    minWidth: '95px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: '#FFFFFF',
    boxSizing: 'border-box',
    boxShadow: 'inset -4px -4px 10px rgba(0, 0, 0, 0.15), inset 5px 5px 10px rgba(0, 0, 0, 0.15)',
  },
  corner: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: '#575757',
    float: 'left',
    paddingLeft: '5px'
  }, 
  center: {
    '& .MuiTypography-root': {
      maxWidth: '103px',
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'relative',
    top: '-27px',
  },
  securityPoint: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '12px',
    letterSpacing: '0.46px',
    textTransform: 'uppercase',
    color: '#575757',
  },
}))

/**
 * RENDERS THE BOARD
 */
export const Grid = () => {
  const [,, tileCountX, tileCountY] = useBoard();
  const classes = useStyles();

  const renderTop = () => {
    const cells = [] as JSX.Element[];
    const labels = ['BÁSICO', 'INTERMEDIO', 'AVANZADO', 'EXPERTO'];
    for (let i=0; i < 4; ++i) {
      cells.push(
        <div key={`top-${i}`} className={`${classes.greyCell} ${classes.square}`} style={{gridColumn: `${i*2+1}/${i*2+3}`}}>
          { 
            labels[i]
          }
        </div>);
    }

    return cells;
  }

  const renderBot = () => {
    const cells = [] as JSX.Element[];
    cells.push(
      <div key={`start`} className={`${classes.blackCell} ${classes.square} ${classes.white}`}>
        SALIDA
      </div>);
    cells.push(
      <div key={`logo`} className={`${classes.greyCell} ${classes.square}`} style={{gridColumn: `2/8`}}>
        <img src="/static/images/logo.png" alt="Logo"/>
      </div>);
    cells.push(
      <div key={`end`} className={`${classes.greenCell} ${classes.square} ${classes.white}`}>
        LLEGADA
      </div>);
    return cells;
  }

  const renderGrid = () => {
    const length = tileCountX * tileCountY;
    const cells = [] as JSX.Element[];

    for (let index = 0; index < length; index++) {
      const type = CELL_INFO[index] ? CELL_INFO[index].type : S_TYPE.YELLOW;
      const backgroundColor = setBackgroundColor(type);

      const white = type === S_TYPE.START || type === S_TYPE.END;
      const normal = type !== S_TYPE.SECURITY_POINT && type !== S_TYPE.LOGO && type !== S_TYPE.BLACKHOLE && type !== S_TYPE.MULTICOLOR;

      cells.push(
      <div key={`${index}`} className={`${backgroundColor} ${classes.square} ${white ? classes.white: ''}`}>
        { normal &&
          CELL_INFO[index] ? CELL_INFO[index].label : ''
        }
        { type === S_TYPE.SECURITY_POINT && 
            <Box height="100%" >
              <Typography className={classes.corner}>{CELL_INFO[index] ? CELL_INFO[index].label : ''}</Typography>
              <Box className={classes.center}>
                <Typography className={classes.securityPoint}>PUNTO DE SEGURIDAD</Typography>
              </Box>
            </Box>
        }
        { type === S_TYPE.BLACKHOLE &&
          <img width="100%" src="/static/images/blackhole.png" alt="Logo"/>
        }
        { type === S_TYPE.MULTICOLOR &&
          <img width="100%" src="/static/images/multicolor.png" alt="Logo"/>
        }
      </div>);
    }

    return cells;
  };

  const setBackgroundColor = (type: S_TYPE) => {
    switch(type) {
      case S_TYPE.YELLOW: return classes.yellowCell;
      case S_TYPE.BLUE: return classes.blueCell;;
      case S_TYPE.ORANGE: return classes.orangeCell;
      case S_TYPE.GREEN:
      case S_TYPE.END: return classes.greenCell;
      case S_TYPE.SECURITY_POINT: 
      case S_TYPE.SIDE: 
      case S_TYPE.LOGO: return classes.greyCell;
      case S_TYPE.BLACKHOLE: 
      case S_TYPE.START: return classes.blackCell;
    }
  }

  return <div className={classes.grid}>{renderTop()}{renderGrid()}{renderBot()}</div>;
};