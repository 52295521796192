import React from "react";

export const BoardContext = React.createContext({
  containerWidth: 0,
  containerHeight: 0,
  tileCountX: 8,
  tileCountY: 10,
});

type Props = {
  containerWidth: number;
  containerHeight: number;
  tileCountX: number;
  tileCountY: number;
  children: any;
};

export const BoardProvider = ({
  children,
  containerWidth = 0,
  containerHeight = 0,
  tileCountX = 8,
  tileCountY = 10,
}: Props) => {
  return (
    <BoardContext.Provider value={{ containerWidth, containerHeight, tileCountX, tileCountY }}>
      {children}
    </BoardContext.Provider>
  );
};