import { Box, makeStyles, Theme, Typography } from '@material-ui/core'
import React, { FC } from 'react'
import { User } from '../../../../shared/models';

const useStyles = makeStyles((theme: Theme) =>({
  root: {
    '& img': {
      position: 'relative',
      top: '55px',
      left: '-75px',
      width: '43px',
      height: '99px',
    },
    width: '175px',
    minHeight: '125px'
  },
  nickname: {
    '& .text': {
      fontFamily: 'Luckiest Guy',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '26px',
      letterSpacing: '0.46px',
      textTransform: 'uppercase',
      color: '#575757',
      wordBreak: 'break-all'
    },
    '& .MuiTypography-root': {
      marginLeft: '40px',
    },
    background: '#FFFFFF',
    borderRadius: '24px',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  turn: {
    '& .MuiTypography-root': {
      color: '#FFFFFF !important',
      width: '100%',
      background: 'transparent !important',
    },
    background: '#575757 !important',
  }
}))

interface PlayerTagProps {
  user: User | undefined;
  myTurn: boolean;
  pos: number;
  color: string;
}

const PlayerTag: FC<PlayerTagProps> = ({user, myTurn, pos, color}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img src={`/static/images/player_${color}.png`} alt="" />
      <Box className={classes.nickname + (myTurn? ' '+classes.turn: '')}>
         { user &&
          (<Typography className={'text'+ (myTurn? ' '+classes.turn: '')}>
            {user.nickname}
          </Typography>)
         }
         { !user &&
          <Typography className={'text'+ (myTurn? ' '+classes.turn: '')}>
            JUGADOR { pos } 
          </Typography>
         }
      </Box>
    </Box>
  )
}

export default PlayerTag
