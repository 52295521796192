import { useEffect, useRef } from "react";

/**
 * `usePrev` stores the previous value of the hook.
 *
 * @link https://blog.logrocket.com/how-to-get-previous-props-state-with-react-hooks/
 * @param value
 * @returns {any}
 */
export const usePrev = (value: any) => {
  const ref = useRef<any>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};