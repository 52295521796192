import { Box, Card, makeStyles, Slider, Theme, Typography } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'

import VolumeUp from '@material-ui/icons/VolumeUp';
import CloseIcon from '@material-ui/icons/Close';

import { audioService, currentMusicVolume, currentSoundVolume } from '../views/gameView/Game/hooks/audioService.service';
import { SOUNDS, useAudio } from '../views/gameView/Game/hooks/useAudio';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '333px',
    maxHeight: '134px',
    right: '0px',
    padding: '18px'
  },
  container: {
    textAlign: 'end',
  },
  icon: {
    cursor: 'pointer',
    marginTop: '18px',
    color: 'white',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
  },
  row: {
    display: 'flex',
  }, 
  close: {
    cursor: 'pointer',
  },
  setting: {
    display: 'flex',
    alignItems: 'center'
  },
  sliderLabel: {
    width: '54px',
  },
  slider: {
    width: '211px',
    marginLeft: '12px'
  }
}))

interface AudioSettingsProps {
}

const AudioSettings: FC<AudioSettingsProps> = () => {
  const classes = useStyles();
  const [showVolumeSettings, setShowVolumeSettings] = useState<boolean>(false);

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  const [soundValue, setSoundValue] = useState(0);
  const [musicValue, setMusicValue] = useState(0);

  const handleSoundVolumeChange = (event: any, newValue: number | number[]) => {
    setSoundValue(newValue as number);
    audioService.changedSoundVolume(newValue as number);
  }

  const handleMusicVolumeChange = (event: any, newValue: number | number[]) => {
    setMusicValue(newValue as number);
    audioService.changedMusicVolume(newValue as number);
  }

  useEffect(() => {
    setSoundValue(currentSoundVolume);
    setMusicValue(currentMusicVolume);

    let soundSubscription = audioService.getNewSoundVolume().subscribe((data: any) => {
      setSoundValue(data.newVolume);
    })
    let musicSubscription = audioService.getNewMusicVolume().subscribe((newVolume: any) => {
      setMusicValue(newVolume);
    })
    
    let changedSubscription = audioService.getChangedByCode().subscribe((data:any) => {
      setMusicValue(data.newVolume as number);
    })
    return () => {
      soundSubscription.unsubscribe();
      musicSubscription.unsubscribe();
      changedSubscription.unsubscribe();
    }
  }, [])

  return (
    <Box className={classes.container}>
      <VolumeUp 
        className={classes.icon}
        onClick={() => {setShowVolumeSettings(true); buttonClickAudio.play()}}/>
        {
          showVolumeSettings && 

          <Card className={classes.root}>
            <Box className={classes.top}>
              <Box className={classes.row}>
                <VolumeUp color="action" style={{marginRight: '10px'}}/>
                <Typography className={classes.title}>
                  VOLUMEN
                </Typography>
              </Box>
              <CloseIcon className={classes.close} color="inherit" onClick={() => {setShowVolumeSettings(false); buttonClickAudio.play();}}/>
            </Box>
            <Box className={classes.setting}>
              <Typography className={classes.title + ' ' + classes.sliderLabel} >
                Sonidos
              </Typography>
              <Box className={classes.slider}>
                <Slider 
                  value={soundValue ?? 0}
                  min={0}
                  max={1}
                  step={0.1}
                  onChange={handleSoundVolumeChange}
                  color="primary"
                />
              </Box>
            </Box>
            <Box className={classes.setting}>
              <Typography className={classes.title + ' ' + classes.sliderLabel} >
                Música
              </Typography>
              <Box className={classes.slider}>
                <Slider 
                  value={musicValue ?? 0}
                  min={0}
                  max={0.1}
                  step={0.01}
                  onChange={handleMusicVolumeChange}
                  color="primary"
                />
              </Box>
            </Box>
          </Card>
        }
    </Box>
  )
}

export default AudioSettings
