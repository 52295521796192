import { Box, makeStyles, Theme } from '@material-ui/core'
import React, { FC } from 'react'
import CategoryCard from './CategoryCard';
import { S_TYPE } from './Grid';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    background: 'rgba(41, 95, 155, 0.65);',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3000,
  },
  card: {
    width: '48%',
    height: '67%',
    overflow: 'visible',
    background: '#FFFFFF',
    boxShadow: '-4px 4px 5px 3px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

interface CategoryModalProps {
  category: any;
  topic: any;
}

/**
 * Display the category of the question for CATEGORY_SHOW_TIME
 */
const CategoryModal: FC<CategoryModalProps> = ({category, topic}) => {
  const classes = useStyles();

  const getCategory = (type: S_TYPE) => {
    switch (type) {
      case 0: return topic?.subtopic1;
      case 1: return topic?.subtopic2;
      case 2: return topic?.subtopic3;
      case 3: return topic?.subtopic4;
    }
    return topic?.subtopic1;
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.card}>
        <CategoryCard title={getCategory(category)} type={category}/>
      </Box>
    </Box>
  )
}

export default CategoryModal
