import { Box, makeStyles, Theme } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'

import CancelIcon from '@material-ui/icons/Cancel';
import InfoIcon from '@material-ui/icons/Info';
import GroupIcon from '@material-ui/icons/Group';
import Rules from './Rules';
import PlayerList from './PlayerList';
import { SOUNDS, useAudio } from './Game/hooks/useAudio';
import { PlayerMeta } from './Game/models/Player.model';
import AudioSettings from '../../shared/AudioSettings';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: 1024,
    top: 0,
    right: '32px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'end'
  },
  icon: {
    cursor: 'pointer',
    marginTop: '18px',
    color: 'white',
  }
}))

interface MenuProps {
  onExit: () => void;
  isAdmin: boolean;
  players: PlayerMeta[];
  maxPlayers: number;
  onKick: (index: number) => void;
}

const Menu: FC<MenuProps> = ({onExit, isAdmin, players, maxPlayers, onKick}) => {
  const classes = useStyles();
  const [showRules, setShowRules] = useState<boolean>(false);
  const [showList, setShowList] = useState<boolean>(false);

  const buttonClickAudio = useAudio(SOUNDS.BUTTON_CLICK, {})

  useEffect(() => {
    if (showList) setShowRules(false);
  }, [showList])

  return (
    <Box className={classes.root}>
      <Box>
        <CancelIcon 
          className={classes.icon}
          onClick={() => {onExit(); buttonClickAudio.play()}}/>
      </Box>
      <Box>
        <InfoIcon 
          className={classes.icon}
          onClick={() => {setShowRules(true); setShowList(false); buttonClickAudio.play();}}
          />
        {
          showRules && 
          <Rules onClose={() => {setShowRules(false); buttonClickAudio.play();}} inline={false}/>
        }
      </Box>
      {
        isAdmin &&
        <Box>
          <GroupIcon 
            className={classes.icon}
            onClick={() => {setShowList(true); buttonClickAudio.play();}}
          />
          {
            showList && 
            <PlayerList onClose={() => {setShowList(false); buttonClickAudio.play();}} players={players} maxPlayers={maxPlayers} onKick={(i) => onKick(i)}/>
          }
        </Box>
      }
      <Box>
        <AudioSettings></AudioSettings>
      </Box>
    </Box>
  )
}

export default Menu
