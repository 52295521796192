import React from 'react';
import type { FC } from 'react';

import { Box, makeStyles, Theme } from '@material-ui/core';

interface BackgroundProps {
  color: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  background: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: -1,
  },
  orange: {
    backgroundImage: `url(/static/images/background_orange.png)`,
    background: '#FFA258', 
    backgroundSize: 'cover',
  },
  blue: {
    backgroundImage: `url(/static/images/background_blue.png)`,
    background: '#8BBFF9', 
    backgroundSize: 'cover',
  },
  yellow: {
    backgroundImage: `url(/static/images/background_yellow.png)`,
    background: '#FAD920',
    backgroundSize: 'cover',
  },
  green: {
    backgroundImage: `url(/static/images/background_green.png)`,
    background: '#83CD24',
    backgroundSize: 'cover',
  }
}))

const Background: FC<BackgroundProps> = ({
  color
}) => {
  const classes = useStyles();
  let backgroundColor;
  switch(color) {
    case 'orange': backgroundColor = classes.orange; break;
    case 'blue': backgroundColor = classes.blue; break;
    case 'yellow': backgroundColor = classes.yellow; break;
    case 'green': backgroundColor = classes.green; break;
  }

  return (
    <Box className={`${classes.background} ${backgroundColor}`} />
  )
}

export default Background;
