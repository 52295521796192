import { Card, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { FC } from 'react'
import { S_TYPE } from './Grid'

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: '100%',
    height: '100%',
    overflow: 'visible',
    background: '#FFFFFF',
    boxShadow: '-4px 4px 5px 3px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '36px',
    lineHeight: '26px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
    marginTop: '4rem'
  },
  orange: {
    backgroundImage: `url('/static/images/background_orange.png')`,
    background: '#FFA258', 
    backgroundSize: 'cover',
    backgroundRepeat: 'round'
  },
  blue: {
    backgroundImage: `url('/static/images/background_blue.png')`,
    background: '#8BBFF9', 
    backgroundSize: 'cover',
    backgroundRepeat: 'round'
  },
  yellow: {
    backgroundImage: `url('/static/images/background_yellow.png')`,
    background: '#FAD920',
    backgroundSize: 'cover',
    backgroundRepeat: 'round'
  },
  green: {
    backgroundImage: `url('/static/images/background_green.png')`,
    background: '#83CD24',
    backgroundSize: 'cover',
    backgroundRepeat: 'round'
  },
  titleInline: {
    fontFamily: 'Luckiest Guy',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '13px',
    lineHeight: '15px',
    letterSpacing: '0.46px',
    color: '#FFFFFF',
    marginTop: '1rem',
  },
  cardInline: {
    boxShadow: 'unset'
  }
}))

interface CategoryCardProps {
  title: any;
  type: any;
  inline?: boolean
}

const CategoryCard: FC<CategoryCardProps> = ({title, type, inline}) => {
  const classes = useStyles()

  let backgroundColor;
  switch(type) {
    case S_TYPE.ORANGE: backgroundColor = classes.orange; break;
    case S_TYPE.BLUE: backgroundColor = classes.blue; break;
    case S_TYPE.YELLOW: backgroundColor = classes.yellow; break;
    case S_TYPE.GREEN: backgroundColor = classes.green; break;
  }
  return (
    <Card className={classes.card + ' ' + backgroundColor + ' ' + (inline ? classes.cardInline: '')}>
      <img src="/static/images/logo.png" alt="" width={inline? "44px": "165px"}/>
      <Typography className={inline? classes.titleInline: classes.title} style={{textAlign: 'right'}}>{title}</Typography>
    </Card>
  )
}

export default CategoryCard
